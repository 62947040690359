.title-block{
    h1{
        color: $blue;
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 5.4rem;
    }
    & + .paragraph-block{
        margin-top: 40px;  
    }
}