.header{
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    padding-right: 6rem;
    background-color: $bg; 
    font-weight: bold;
    font-size: 1.4rem;
    @media (max-width: 420px){
        padding: 1.2rem 0 1.2rem 0;
        padding: 1.2rem 0 1.2rem 0;
        position: relative;
        height: 40px;
        width: 100vw;
        display: flex;
        align-items: center;
    }
    @media (max-width: 992px){
        padding: 12px 0;
        height: 54px;
        display: flex;
        align-items: center;
    }
    .language-icon{
        @media (max-width: 420px){
            max-height: 22px;
        }
    }
    .language-text{
        margin-top: 5px;
        @media (max-width: 420px){
            margin-top: 0;
        }
    }    
    a{
        color: black;
        text-decoration: none;
    }
    img{
        vertical-align: middle;
        margin-left: 15px;
        margin-right: 15px;
    }
    .getin{
        margin-right: 50px;
    }
    .phone{
        margin-right: 15px;
        @media (max-width: 420px){
            margin-right: 0;
        }
    }
}
nav.navbar{
    height: 95px;
    margin-bottom: -95px;
    background-color: #233040;
    transition: background-color .3s ease-in-out;
    .social{
        margin-left: 5px;
        margin-right: 5px;
    }
    .search{
        background-color: transparent;
        border: none;
        margin-left: 10px;
        margin-right: -15px;
    }
    body.sticky-nav &, body.blue-nav &{
        background-color: $blue;
    }
    #navbar1{
        &.show{
            margin-left: 0 !important;
            overflow-x: hidden;
            padding-bottom: 200px;
        }
    }
}
.navbar{
    .navbar-brand{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            height: 42px;
            @include media-breakpoint-down(sm){
                height: 36px;
            }
        }
        @include media-breakpoint-up(lg){
            img{
                width: 25vw;
                max-width: 364px;
            }
        }
        body.sticky-nav{ 
            height: 90px;
        }
    }
    .navbar-toggler{
        &:focus, &:focus-visible{
            outline: none !important;
        }
        @include media-breakpoint-down(sm){
            margin-top: 0px;
        }
    }
    @include media-breakpoint-down(sm){
        padding: 0 !important;
    }
    @include media-breakpoint-only(md){
        padding: 0;
            .navbar-brand img{
                transition: margin-top .005s;
            }
    }
    @include media-breakpoint-up(lg){
        padding: 0;
    }
    .navbar-nav{
        color: $white;
        &.menu{
            .nav-item{
                padding-left: 15px;
                padding-right: 15px;
                margin-right: 20px;
                height: 95px;
                line-height: 95px;
                @media (min-width: 767px) and (max-width: 993px){
                    margin-right: 0px;
                }
                a, a:hover{
                    text-decoration: none;
                }
            }   
        }
        .nav-item{
            color: $white;
            white-space: nowrap;
            font-weight: bold;
            position: relative;
            cursor: pointer;
            &:hover{
                &>span{
                    color: $orange;
                }
                &>a{
                    color: $orange!important;
                }
                .subnav{
                    display: block;
                }
            }
            .subnav{
                display: none;
                min-width: 320px;
                position: absolute;
                padding: 30px 50px;
                max-height: 100vh;
                background-color: $blue;
                body.sticky-nav &, body.blue-nav &{
                    top: 95px;
                    .navbar-brand{
                        img{
                            @media (min-width: 768px) and (max-width: 992px){
                                margin-top: 0 !important;
                                transition: margin-top .005s;
                            }
                        }
                    }
                }
                .title{
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid $orange;
                    font-size: 1.8rem;
                    line-height: 1.5;
                }
                ul{
                    padding: 0;
                    li{
                        list-style: none;
                        font-size: 16px;
                        line-height: 2;
                        a{
                            text-decoration: none;
                            &:hover{
                                color: $orange!important;
                            }
                        }
                        svg {
                            transition: transform .2s linear;
                            path{
                                fill: $orange;
                            }
                        }
                        &:hover{
                            svg{
                                transform: translateX(5px);
                            }
                        }
                    }
                }
                @media(max-width: 768px){
                    display: block;
                }
            }
            a{
                color: $white;
            }
        }
    }
}
body.sticky-nav, body.blue-nav{
    @media (min-width: 768px) and (max-width: 992px){
        .sticky-top{
            height: 95px;
        }
    }
    .hamburger-container{
        @include media-breakpoint-down(lg){
            margin-top: 0px;
            .navbar-brand{
                top: 0px !important;
            }
        }
        @include media-breakpoint-down(sm){

            .navbar-toggler{
                margin-top: -5px;
            }
        }
    }
}
@media (max-width: 992px){
    body:not(.page-homepage) .header{
        display: none;
    }
    .header_image{        
            max-height: 580px;
    }
    .navbar{
        display: block;
    }
    .navbar-brand{
        position: absolute;
        top: -3px;
        left: 0;
        img{
            transition: all .3s ease-in-out;
            width: auto;
            margin-top: 14px;
        }
    }
    nav {
        body.sticky-nav &, body.blue-nav &{
            background-color: $blue;
        }
        body.sticky-nav &{
            #navbar1{
                transition: all .3s ease-in-out;
                // background-color: rgba(1, 58, 85, 0.9);
                margin-top: -6px;
            }
        }
    }
    body.sticky-nav{
        display: inherit;
    }
    .hamburger-container{
        float: right;
        max-width: 100% !important;
        .navbar-brand{
            position: relative;
            margin-top: -14px;
            @include media-breakpoint-down(md){
                width: 83%;
                margin-right: 0;
                justify-content: start;
                display: flex;
                margin-top: -9px;  
            }
        }
        button{
            width: 30px;
            height: 30px;
            z-index: 10000;
            margin-right: 30px;
            .fa-1x {
                font-size: 1.5rem;
            }
        }
        #navbar1{
            padding-top: 30px;;
            background-color: $blue;
            height: 100vh;
            margin-top: -13px;
            .menu{
                margin-bottom: 20px;
                display: block;
                .nav-item{
                    line-height: 0;
                    font-size: 3rem;
                    display: block;
                    height: auto;
                    margin-top: 50px;
                    margin-left: 30px;
                }
            }
            .subnav{
                padding: 20px 20px;
                z-index: 1000;
                transition: max-height .3s ease-in-out;
                overflow: hidden;
                max-height: 100vh;
                min-width: 300px;
                position: relative;
                top: 0;
                background-color: transparent;
                ul li{
                    font-size: 2rem;
                }
            }
        }
        .social-row{
            padding: 30px 0 40px;
            flex-direction: row;
            margin-left: 40px !important;
        }
        .social{
            img{
                height: 28px;
                margin-right: 14px;
            }
        }
        .animated-icon3 {
            width: 30px;
            height: 20px;
            position: relative;
            margin: 0px;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            &:active{
                outline: none !important;
            }
        }
        .animated-icon3 span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
        .animated-icon3 span {
            background: $white;
        } 
        .animated-icon3 span:nth-child(1) {
            top: 0px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
        .animated-icon3 span:nth-child(2) {
            top: 10px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        } 
        .animated-icon3 span:nth-child(3) {
            top: 20px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
        .animated-icon3.open span:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0px;
            left: 8px;
        } 
        .animated-icon3.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }   
        .animated-icon3.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 21px;
            left: 8px;
        }
    }
}
@media (min-width: 992px) and (max-width: 1199px){    
    .hamburger-container{
        margin-right: 30px;
    }
}
@include media-breakpoint-up(lg){
    nav {
        height: 95px !important;
    }
    body.sticky-nav{     
        &{
            .navbar-brand{
                height: 95px !important;
            }
        }
    }
}
.header_image{
    width: 100vw;
    height: calc(100vh - 70px);
    background-size: cover;
    background-position: center;

    &.top{
        background-position: center top;
    }
}