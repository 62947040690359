.itinerary-block{
    margin-bottom: 100px;
    .title{
        font-size: 32px;
        line-height: 38px;
    }
    .tabs{
        margin-top: 26px;
        width: 100%;
        button{
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: $dark-grey-50;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            text-transform: uppercase;
            &:active, &:focus{
                border: none;
                outline: none;
            }
            &.active{
                background-color: transparent;
                color: $orange;
            }
        }
    }
    .tab-content{
        max-height: 350px;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        &.open{
            max-height: 12000px;
            transition: max-height 1s ease-in-out;
        }
        .paragraph-wrapper{
            column-count: 2;
            column-gap: 36px;
            @include media-breakpoint-down(md){
                column-count: 1;
            }
            h4{
                color: $blue;
                column-span: all;
                font-size: 22px;
                margin-top: 46px;
            }
        }
    }
    .read-more{
        background-color: transparent;
        color: $blue;
        font-size: 18px;
        border: 1px solid $blue;
        padding: 6px 12px;
        position: absolute;
        bottom: -60px;
        &:active, &:focus{
            outline: none;
        }
    }
}