.page-standardpage, .page-standardindexpage{
    .header_img{
        height: 750px;
        background-size: cover;
        position: relative;
        background-position: center;
        @include media-breakpoint-down(sm){
            height: 40vh;
        }
        &.no_img{
            height: 195px;
        }
        .h1-container{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: $orange;
            display: inline;
            height: 100px;
            margin-bottom: 0;
            padding-left: 120px;
            padding-right: 120px;
            white-space: nowrap;
            @include media-breakpoint-down(sm){
                align-items: center;
                display: flex;
                padding-top: 20px;
                padding-bottom: 16px;
            }
            h1{
                line-height: 100px;
                color: white;
                font-size: 4.5rem;
                font-weight: bold;
                @include media-breakpoint-down(sm){
                    white-space: normal;
                    line-height: 5rem;
                }
            }
            &:after{
                content: '';
                position: absolute;
                right: 10%;
                bottom:-22px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 22px 22px 0 22px;
                border-color: $orange transparent transparent transparent;
                z-index: 5;
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
            @include media-breakpoint-down(sm){
                max-width: 100vw;
                width: 100vw;
                text-align: center;
                padding: 20px;
            }
        }
    }
    .excerpt{
        margin-top: 100px;
        margin-bottom: 100px;
        @media (max-width: 1200px){
            margin: 60px auto;
            max-width: 81vw;
        }
        @include media-breakpoint-down(sm){
            max-width: unset;
            margin: 40px 0;
        }
        .rich-text{
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: $dark-grey;
            @include media-breakpoint-down(sm){
                font-size: 2rem;
                line-height: 2.6rem;
                margin-top: 20px;
            }
        }
    }
}