.newsletter-signup-block{
    padding-top: 40px;
    background-color: $blue;
    margin-bottom: 60px;
    @media (max-width: 768px){
        padding: 20px 40px;
    }
    @media (max-width: 640px){
        padding: 14px;
    }
    h2.title{
        font-size: 28px;
        line-height: 32px;
        color: $white;
        padding-top: 26px;
        padding-bottom: 16px;
        text-align: center;
        @include media-breakpoint-down(sm){
            font-size: 22px;
        }
    }
    #mc_embed_signup{
        background-color: $blue;
        form{
            @media (max-width: 768px){
                float: right;
                width: 260px;
            }
            #mc_embed_signup_scroll{
                input{
                    display: block !important;
                    font-size: 1.6rem !important;
                    height: 46px !important;
                    line-height: 46px !important;
                    padding-left: 15px !important;
                    margin-bottom: 30px !important;
                    &::placeholder{
                        color: $blue !important;
                        font-weight: 500 !important;
                    }
                    @media (max-width: 768px){
                        height: 42px !important;
                        line-height: 39px !important;
                        margin-bottom: 20px;
                        &::placeholder{
                            padding-bottom: 12px !important;
                        }
                    }
                }
                .clear{
                    float: left;
                    input{
                        background: transparent;
                        border: 2px solid $white;
                        color: $white;
                        font-weight: bold;
                        font-size: 1.4rem;
                    }

                }

            }
        }
    }
}