@font-face {
    font-family: 'Verlag';
    src: url('../fonts/Verlag-Light.eot');
    src: url('../fonts/Verlag-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Verlag-Light.woff') format('woff'),
        url('../fonts/Verlag-Light.ttf') format('truetype'),
        url('../fonts/Verlag-Light.svg#Verlag-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Verlag';
    src: url('../fonts/Verlag-Book.eot');
    src: url('../fonts/Verlag-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Verlag-Book.woff2') format('woff2'),
        url('../fonts/Verlag-Book.woff') format('woff'),
        url('../fonts/Verlag-Book.ttf') format('truetype'),
        url('../fonts/Verlag-Book.svg#Verlag-Book') format('svg');
    font-weight: 350;
    font-style: normal;
}

@font-face {
    font-family: 'Verlag';
    src: url('../fonts/Verlag-Bold.eot');
    src: url('../fonts/Verlag-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Verlag-Bold.woff2') format('woff2'),
        url('../fonts/Verlag-Bold.woff') format('woff'),
        url('../fonts/Verlag-Bold.ttf') format('truetype'),
        url('../fonts/Verlag-Bold.svg#Verlag-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
