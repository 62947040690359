.map-block{
    position: relative;
    margin-top: 175px;
    margin-bottom: 100px;
    .map-title{
        height: 75px;
        font-size: 3rem;
        line-height: 75px;
        color: $white;
        font-weight: bold;
        padding-left: 50px;
        padding-right: 50px;
        background-color: $blue;
        position: absolute;
        top: -75px;
        left: 32%;
        &:after {
            content: '';
            position: absolute;
            left: 15%;
            bottom:-22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $blue transparent transparent transparent;
            z-index: 5;
        }
    }
}
#map{
    height: 600px;
    width: 100%;
}

@include media-breakpoint-down(sm){
    .map-title{
        font-size: 100% !important;
        padding: 0 20px 0 20px !important;        
        height: 64px !important;
        width: 94%;
        top: -64px !important;
        left: 6% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #map{
        max-height: 300px;

    }
}

@include media-breakpoint-only(md){
    #map{
        max-height: 350px;
    }
}
@include media-breakpoint-only(lg){
    
    .map-block{
        margin-top: 10% !important;

        #map{
            max-height: 400px;
        }
    }
}