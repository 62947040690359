* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
}

body{
    // overflow-x: hidden;
    font-size: 1.8rem;
    font-family: 'Verlag';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
}
a{
    text-decoration: none !important;
    color: $orange;
    list-style: none;
    &:hover{
        color: $orange; 
    }
}
h2{
    font-size: 40px;
    line-height: 48px;
    color: $blue;
    font-weight: bold;
}
h3{
    font-size: 3rem;
    color: $blue;
    font-weight: bold;
}

.intro{
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: $dark-grey;
    font-weight: 300;
}

.block{
    margin-top: 120px;
    margin-bottom: 120px;
    @include media-breakpoint-down(sm){
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

// .max-1440{
//     max-width: 1440px;
//     margin-left: auto;
//     margin-right: auto;
//     overflow: hidden;
// }
#video{
    width: 100%;
    aspect-ratio: 1.77;
    position: relative;
    margin-top: 90px;
    iframe{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@include media-breakpoint-down(sm){
    h1{
        font-size: 3.4rem !important;
    }
    h2{
        font-size: 3rem !important;
        line-height: 1;
        color: $blue;
        font-weight: bold;
    }
    h3{
        font-size: 2.8rem !important;
        color: $blue;
        font-weight: bold;
    }
    
}
