.page-homepage {
    .excerpt{
        margin-top: 100px;
        margin-bottom: 100px;
        @media (max-width: 1200px){
            margin: 60px auto;
            max-width: 81vw;
        }
        @include media-breakpoint-down(sm){
            max-width: unset;
            margin: 40px 0;
        }
        .rich-text{
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: $dark-grey;
            @include media-breakpoint-down(sm){
                font-size: 2rem;
                line-height: 2.6rem;
                margin-top: 20px;
            }
        }
    }
}