.dual-trip-block{
    max-width: 1440px !important;
    .dual-trip-block-col{
        @media (max-width: 420px){
            padding: 0;
        }
    }
    .trip-image-container{
        padding-left:0;
        @media (max-width: 992px){
            margin: 50px 0;
            height: 100%;
        }
        @media (max-width: 420px){
            padding-right: 0;
        }
    }
    .dual-trip-info-container{
        margin-top: -164px;
        @media (max-width: 1200px){
            margin-top: -130px;
        }
        .dual-trip-info-title{
            max-width: 490px;
            margin: 0 auto;
            @media (max-width: 1200px){
                max-width: 440px;
                min-height: 70px;
                height: auto;
                line-height: 32px;
                font-size: 26px;
            }
            @media (max-width: 992px){
                max-width: 60vw !important;
            }
            @media (max-width: 420px){
                max-width: 95vw !important;
            }
            .trip-title{
                width: 65%;
                @media (max-width: 1200px){
                    width:60%
                }
            }
        }
        .dual-trip-info-text{
            max-width: 490px !important;
            opacity: 0.95;
            margin: 0 auto;
            @media (max-width: 1200px){
                max-width: 440px !important;
            }
            @media (max-width: 992px){
                max-width: 60vw !important;
            }
            @media (max-width: 420px){
                max-width: 95vw !important;
            }
        }
    }
    @include media-breakpoint-down(sm){
        .second-trip-image{
            position: relative;
            top: 55%;
        }
    }
    .trip-image{
        height: 450px;
        background-size: cover;
        background-position: center;
        @media (max-width: 992px){
            max-width: 77vw;
            margin: 0 auto;
        }
        @media (max-width: 420px){
            max-width: 100vw;
        }
    }
    .trip-title{
        background: $orange;
        min-height: 85px;
        height: auto;
        line-height: 38px;
        font-size: 30px;
        padding-left: 30px;
        font-weight: bold;
        color: white;
        &:after {
            content: '';
            position: absolute;
            left: 20%;
            bottom:-22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $orange transparent transparent transparent;
            z-index: 5;
        }

        @include media-breakpoint-down(sm){
            font-size: 2.5rem;

        }
    }
    .text{
        background-color: $blue;
        padding: 50px 60px 30px 45px;
        z-index: 2;
        color: $white;
        font-weight: 500;
        @media (max-width: 1200px){
            padding: 30px;
        }
        .rich-text{
            margin-bottom: 28px;
        }

        &.open{
            .additional-info{
                max-height: 250px;
            }
            .more{
                img{
                    transform: rotate(180deg);
                }
            }

            
        }
        @include media-breakpoint-down(sm){
            .rich-text p{
                font-size: 1.7rem;
                margin-bottom: 1.8rem;
            }
        }

        @include media-breakpoint-down(sm){
            line-height: 21px;
            padding: 42px 35px 30px 30px;
        }

        .additional-info{
            max-height: 0;
            overflow: hidden;
        }

        .more{
            display: table-cell;
            margin-top: 10px;
            border: 1px solid $white;
            border-radius: 0;
            width: 135px;
            height: 46px;
            background-color: transparent;
            color: white;
            cursor: pointer;
            line-height: 46px;
            font-size: 2rem;

                @include media-breakpoint-down(sm){
                    font-size: 1.4rem;
                    width: 100px;
                    height: 36px;
                    line-height: 0;
                    margin-top: 20px;
                }
        }
    }


}



@include media-breakpoint-up(lg){

    .dual-trip-block{
        margin-top: 30px !important;
        .second-info-column{
            margin-left:0;
        }
    }
}
@include media-breakpoint-only(md){
    .dual-trip-block{
        margin-top: 30px;
    }
}
@include media-breakpoint-between(md, lg){
    .dual-trip-block{
        margin-top: 30px;
        .trip-image{
            max-height: 320px;

        }
    }
}

