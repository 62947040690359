.media-carousel-block{
    position: relative;
    margin-bottom: 110px;
    .media-wrapper{
        overflow: scroll;
        scroll-snap-type: x mandatory;
    }
    .video, .image{
        width: 100vw !important;
        min-height: 750px;
        max-height: 750px;
        scroll-snap-align: start;
        @include media-breakpoint-down(sm){
            max-height: 350px;
            min-height: 350px;
        }
        iframe{
            min-height: 750px;
            width: 100vw !important;
            @include media-breakpoint-down(sm){
                min-height: 350px;
                margin-top: 0 !important;
            }
        }
        img{
            min-width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100vw;
            min-width: 100vw;
        }
    }
    .prev, .next{
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
        @include media-breakpoint-down(md){
            svg{
                height: 40px;
            }
        }
    }
    .prev{
        left: 28px;
        @include media-breakpoint-down(md){
            left: 12px;
        }
    }
    .next{
        right: 28px;
        @include media-breakpoint-down(md){
            right: 12px;
        }
        svg{
            transform: rotate(180deg);
        }
    }
}