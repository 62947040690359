.list-trip-block{
    background-color: $grey;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    position: relative;
    margin-top: 175px;
    margin-bottom: 100px;
    .inner-title{
        color: $blue;
        font-size: 45px;
        letter-spacing: 0.9px;
        font-weight: 700;
        line-height: 54px;
        margin-bottom: 56px;
        @include media-breakpoint-down(md){
            font-size: 36px;
            line-height: 44px;
        }

    }
    .title{
        height: 75px;
        font-size: 2.8rem;
        line-height: 75px;
        color: $white;
        font-weight: bold;
        padding-left: 50px;
        padding-right: 50px;
        background-color: $blue;
        position: absolute;
        top: -75px;
        right: 32%;
        &:after {
            content: '';
            position: absolute;
            bottom:-22px;
            width: 0;
            left: 10%;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $blue transparent transparent transparent;
            z-index: 5;
        }
    }
    .info{
        color: $white;
        margin-top: 10px;
        &>div{
            height: 30px;
            margin-bottom: 10px;
            white-space: nowrap;
            svg{
                vertical-align: text-bottom;
                margin-right: 12px;
            }
        }
    }
    .description{
        color: $white;
        padding-bottom: 30px;
    }
    .price{
        bottom: 80px;
        color: $white;
        font-weight: 600;
        font-size: 3rem;
        left: 50%;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        white-space: nowrap;
    }
    
}
@include media-breakpoint-down(sm){
    .list-trip-block{
        margin-bottom: 20px !important;
        margin-top: 120px !important;
        .title{
            width: 100%;
            font-size: 2.4rem;
            padding-left: 32px;
            right: 0%;
            &::after{
                left: 45%;
            }
        }
    }
}
.list-trip-block + footer{
    margin-top: -100px;
}