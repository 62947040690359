.header_image + .media-block{
    margin-top: -134px !important;
    @include media-breakpoint-down(sm){
        margin-top: -120px !important;
        padding-right: 0;
        padding-left: 0;
    }
}
.media-block{
    max-width: 1440px;
    overflow: hidden;
    margin-bottom: 90px !important;
    margin-top: 40px !important;
    .heading-container{
        position: relative;
        @include media-breakpoint-down(sm){
            max-width: 100vw;
            padding-right: 0;
            padding-left: 0;
            .heading-wrapper{
                background-color: $grey;
            }
        }
    }
    .content-container{
        padding: 0;
        margin-bottom: 30px;
        @include media-breakpoint-down(sm){
            .gallery{
                padding: 0;
            }
        }
        @include media-breakpoint-down(sm){
            max-width: 100vw;
        }
    }
    &.block-left{
        .heading-container{
            &:before{
                content:'';
                position: absolute;
                width: 500%;
                right: 100%;
                height: 100%;
                background-color: $grey;
            }
            @include media-breakpoint-down(sm){
                &:before{
                    display: none;
                }
            }
        }
        .content-container{
            margin-left: -15px;
            @include media-breakpoint-down(sm){
                margin: 0 auto;
            }
            .carousel-indicators {
                left: auto;
            }
        }
    }
    &.block-right{
        .heading-container{
            &:before{
                content:'';
                position: absolute;
                width: 500%;
                left: 100%;
                height: 100%;
                background-color: $grey;
            }
        }
        .content-container{
            margin-right: -15px;

            @include media-breakpoint-down(sm){
                margin-right: 0;
                margin-top: 0;
            }
            .carousel-indicators {
                right: auto;
            }
        }
    }

    &.title-right{
        .title-wrapper{
            order: 6;
            @media (max-width: 992px){
                padding: 0;
            }
            .title{
                margin-left: -15px;
                @media (max-width: 992px){
                    margin-left: 0;
                    width: 100%;
                }
                &::before{
                    left: -22px;
                    border-width: 22px 22px 22px 0;
                    border-color: transparent $grey transparent transparent;
                }
                @include media-breakpoint-down(sm){
                    h2{
                    font-size: 2.8rem;
                    width: 100%;
                    }
                }


            }

        }
        .text-wrapper{
            .text{
                min-width: 100%;
                min-height: 100%;
            }
        }
        
    }
 

    &.title-left{
        .title-wrapper{
            @media (max-width: 992px){
                padding: 0;
            }
            .title{
                margin-right: -15px;
                @media (max-width: 992px){
                    width: 100%;
                }
                &::before{
                    right: -22px;
                    border-width: 22px 0 22px 22px;
                    border-color: transparent transparent transparent $grey;
                }
            }
        }
        
        .text{
            left: 0;
            right: 15px;
            min-width: 100%;
            min-height: 100%;
        }
    }

    .title{
        margin-right: -15px;
        margin-left: -15px;
        text-align: center;
        background-color: $grey;
        color: $blue;
        z-index: 3;
        padding: 43px 0;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            z-index: 5;
            @include media-breakpoint-down(sm){
                content: none;
            }
        }
        h2{
            font-size: 40px;
            font-weight: bold;
            margin: 0;
        }
    }

    .text{
        position: absolute;
        background-color: $blue;
        right: 0;
        top: 0;
        padding: 50px;
        z-index: 2;
        font-weight: 300;
        color: $white;

        &.open{
            .additional-info{
                max-height: 250px;
            }
            .more{
                img{
                    transform: rotate(180deg);
                }
            }
        }

        .additional-info{
            max-height: 0;
            overflow: hidden;
        }

        .more{
            position: absolute;
            bottom: -30px;
            left: 45;
            border: none;
            border-radius: 0;
            width: 146px;
            height: 60px;
            background-color: $orange;
            color: $white;
            font-weight: bold;
            font-size: 2rem;
            cursor: pointer;
        }
    }

    .carousel-indicators{
        bottom: -30px;
        margin: 0;
        li{
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $blue;
            border: none;
            &.active{
                background-color: $orange;
            }
        }
    }
    .carousel-control{
        color: black;
    }

    .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 40px;
            width: 40px;
        }
}

@include media-breakpoint-down(sm){
    .media-block{
        margin-bottom: 20px !important;
        margin-top: 20px !important;
        .heading-container{
            .heading-wrapper{
                .title-wrapper{
                    order: 1;
                    .title h2{
                        font-size: 3.5rem;
                    }
                }
                .text-wrapper{
                    order: 2;
                    .rich-text{
                        font-size: 1.5rem;
                        line-height: 1.9rem;
                    }
                    .more{
                        width: 116px;
                        height: 46px;
                        font-size: 1.7rem;
                    }
                }
            }
            .content-container{
                margin-left: 0;
                margin-top: 0;
            }
        }
    }
    .carousel{
        .carousel-control-prev-icon, .carousel-control-next-icon{
            height: 20px;
        }
    }
    .block-right{
        .heading-wrapper{ 
            .title-wrapper{
                order: 1
            }
            .text-wrapper{
                order: 2;
            }
        }
    }
    .media-block{
        .video{
            padding: 0;
            iframe{
                margin-top: -85px;
                margin-bottom: -100px;
                max-width: 640px;
                max-height: 380px;
                width: 100%;
            }
        }
        .text-wrapper{
            padding: 0;

            .text{
                position: relative;
                right: 0;
                padding: 25px;
            }
        }
    }
    .block-right{
        margin-top: 30px;
    }
    .block-left{
        margin-top: -64px !important;
    }
}
@include media-breakpoint-down(md){
    .media-block{
        margin-bottom: 0;
        .heading-container{
            .title-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                .title{
                    padding: 22px;
                    h2{
                        font-size: 2.8rem;
                        line-height: 3.8rem;
                    }
                }
            }
            .text{
                padding: 26px 46px;
                min-height: 100%;
                .rich-text{
                    line-height: 2.6rem;
                    p{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .rich-text{
            font-size: 1.6rem;
            line-height: 1.9rem;
        }
    }
    .video{
        padding: 0;

        iframe{
            margin-top: -109px;
            max-width: 780px;
            width: 100%;
        }
    }
    .block-left{
        margin-top: -100px;
    }
    .block-right{
        margin-top: 0;
    }
}



@include media-breakpoint-only(lg){
    .block{
        margin-bottom: 0;
        margin-top: 0;
    }

    .video{
        padding: 0;

        iframe{
            max-width: 1440px;
            width: 100%;
        }
    }
}