.booking-button-block{
    position: -webkit-sticky;
    position: sticky;
    top: 13%;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    z-index: 10000;
    .booking-button-container{
        .booking-button{
            background-color: $orange;
            padding: 12px 24px;
            color: $white;
            font-weight: 800;
            font-size: 26px;
        }
    }
}
