.question-list-block{
    margin-top: 175px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;

    .block-title{
        height: 75px;
        font-size: 2.8rem;
        line-height: 75px;
        color: $white;
        font-weight: bold;
        padding-left: 50px;
        padding-right: 50px;
        background-color: $orange;
        position: absolute;
        top: -75px;
        right: 35%;
        @media (max-width: 768px){
            right: 0%;
            width: 100vw;
            &::after{
                display: none;
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 15%;
            bottom:-22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $orange transparent transparent transparent;
            z-index: 5;
        }
    }
}



.faq-container{
    margin-bottom: 10px;
    position: relative;
    min-height: 70px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 26px 0 20px 0;
    &.answer-container-open{
        height: 100%;
        .faq-question{
            margin-bottom: 24px;
        }
    }
    .plus-icon{
        position: absolute;
        left: 90px;
        top: 24px;
        @include media-breakpoint-down(md){
            left: 30px;
        }
        img{
            width: 30px;
            margin-bottom: 12px;
            transition-duration: .2s;
        }
    }
    .faq-question{
        cursor: pointer;
        display: inline;
        font-size: 24px;
        line-height: 26px;
        color: $blue;
        font-weight: 500;
        position: relative;
        padding-left: 160px;
        padding-right: 40px;
        @include media-breakpoint-down(md){
            padding-left: 100px;
        }
        .question{
            display: inline;
            @media (max-width: 580px){
                font-size: 20px;
            }
        }
        .faq-answer{
            display: none;
            p{
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                padding-top: 30px;
                padding-right: 30px;
                li{
                    position: relative;
                    list-style-type: none;
                    &::before {
                        content: '\2022';
                        color: $orange;
                        display: inline-block;
                        font-size: 18px;
                        position: absolute;
                        left: -26px;
                    }
                }
            }
        }
    }
    &.answer-container-open{
        .plus-icon{
            img{
                transform: rotate(45deg);
                transition-duration: .2s;
            }
        }
    }
}