.social-block{
    overflow: hidden;
    max-height: 509px;
    margin: 120px auto;
    @include media-breakpoint-down(lg){
        max-height: unset;
        // overflow: unset;
    }
    .social-container{
        @include media-breakpoint-down(lg){
        margin: 0;
        }
        .row{
            flex-wrap: nowrap;
            @media (max-width: 1600px) {
                margin-left: auto;
                width: calc(100% - ((100vw - 1140px) / 2));
            }
            @include media-breakpoint-down(lg){
                flex-direction: column;
                width: 100%;
                margin: 0px;
            }
        }
    }
    h2{
        font-weight: bold;
        font-size: 5rem;
        margin-bottom: 4.6rem;
        color: $white;
    }

    .description{
        width: 90%;
    }

    .blue-left{
        background-color: $blue;
        color: $white;
        position: relative;
        width: 100%;
        max-width: 927px;
        padding-right: 70px;
        &:before{
            content: '';
            width: 500%;
            right: 100%;
            position: absolute;
            height: 100%;
            background-color: inherit;
        }
        @include media-breakpoint-down(lg){
            max-width: 100%;
        }
        @include media-breakpoint-between(lg, xl){
            padding-right: 200px;
        }
        .content {
            @media (max-width: 1200px) {
                padding: 0px 40px;
            }
            @include media-breakpoint-down(lg){
                padding: 60px 40px;
            }
            h2 {
                @media (max-width: 1200px) {
                    font-size: 4.3rem;
                } 
            }
            ul {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
        }
    }
    .grey-right{
        padding-left: 0;
        padding-right: 0;
        width: 27vw;
        min-width: 513px;
        height: 500px;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
        @include media-breakpoint-down(sm){
            min-width: unset;
        }
        .iframe-container{
            position: absolute;
            left: 54%;
            right: 0;
            width: 46%;
            height: 500px;
            overflow: hidden;
            iframe{
                width: 1500px;
                height: 100%;
            }
        }
    }
    .social-row{
        @include media-breakpoint-down(lg){
            padding: 20px 40px;
        }
        img{
            height: 30px;
            margin: 10px;
        }
    }
    a{
        color: $orange;
        font-weight: bold;
    }
    .navbar-nav{
        margin-top: 1rem;
        flex-direction: row;
        .nav-item{
            margin-right: 10px;
        }
    }
}
@include media-breakpoint-down(lg){
    .social-block{
        .grey-right{
            height: 33vw;
            .iframe-container{
                position: relative;
                width: 100%;
                left: auto;
                right: auto;
                height: 33vw;
                iframe{
                    width: 100%;
                    height: 33vw;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .social-block{
        margin-top: 30px;
        padding: 0;
        h2{
            font-size: 4rem;
            margin-bottom: 1.6rem;
        }
        .description{
            display: none;
        }
        .blue-left{
            .navbar-nav{
                padding-bottom: 10px;
            }
        }
        .social-row{
            img{
                height: 30px;
                margin: 4px 4px 12px 4px;
            }
        }
    }
}


  @include media-breakpoint-between(sm, md){
    .social-block{
        h2{
            margin-bottom: 2.6rem;
            font-size: 4rem;
        }
        .blue-left{
            .navbar-nav{
                margin-bottom: 2rem;
            }
            .description{
                width: 80%;
            }
        }
        .grey-right::after{
            display: none;
        }
    }
    .social-block-row{
        width: 100vw;
    }
  }
  