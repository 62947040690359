#triggerContainer {
    position: fixed;
    bottom: 100px;
    right: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 2147483647; /* Maximum z-index value */
    opacity: 0;
    transform: translate(20%, 20%);
    transition: opacity 0.5s ease, transform 0.5s ease;
    pointer-events: none;
}

.triggerButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 2147483647; /* Maximum z-index value */
}

.triggerButton img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: opacity 0.3s ease;
    z-index: 2147483647; /* Maximum z-index value */
    border: 5px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.triggerButton img:hover {
    opacity: 0.8;
}

#triggerTextButton {
    margin-bottom: 10px;
    position: relative;
    z-index: 2147483647; /* Maximum z-index value */
}

#triggerText {
    font-size: 14px;
    color: black;
    background-color: #F4F4F5;
    padding: 20px;
    border-radius: 25px 25px 0 25px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    max-width: 248px;
    word-wrap: break-word;
    transform: translateZ(0);
    z-index: 2147483647; /* Maximum z-index value */
}

#closeButton {
    position: absolute;
    top: -40px;
    right: 0;
    background-color: #F4F4F5;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    line-height: 0;
    z-index: 2147483647; /* Maximum z-index value */
}

#closeButton:hover {
    background-color: #e0e0e0;
}

#closeButton svg {
    width: 1em;
    height: 1em;
    stroke: currentColor;
    fill: currentColor;
    stroke-width: 0;
    vertical-align: middle;
    z-index: 2147483647; /* Maximum z-index value */
}

#triggerImageButton {
    position: fixed;
    bottom: 45px;
    right: 45px;
    z-index: 2147483647; /* Maximum z-index value */
    opacity: 1 !important;
    visibility: visible !important;
}

.hidden-container {
    width: 0;
    height: 0;
}