.accommodation-block{
    margin-top: 100px;
    margin-bottom: 100px;
    @media (max-width: 768px){
        margin: 0 auto !important;
    }
    @media (max-width: 420px){
        max-width: 100vw;
    }
    @media (min-width: 992px) and (max-width: 1330px){
        max-width: 880px;
    }
    .row{
        @media (max-width: 420px){
            max-width: 100%;
            padding: 0;
            margin: 0;
        }
        .btn-primary{
            &:hover{
                background-color: $blue !important;
                cursor: pointer;
            }
        }
        .btn-secondary{
            &:hover{
                background-color: $orange !important;
                cursor: pointer;
            }
        }
    }
    .intro{
        width: 80%;
        margin-bottom: 3rem;
    }
    &.image-left{
        .paragraph{
            order: 6;
        }
        .image{
            order: 1;
        }
        .buttons{
            float: left;
            margin-left: -30px;
            margin-right: auto;
        }
        @media (max-width: 768px){
            margin: 0;
            padding: 0;
            margin-bottom: 40px;
            .image{
                order: -1;
            }
        }
        // @media (min-width: 992px) and (max-width: 1340px){
        //     .paragraph-wrapper{
        //         margin-right: 0 !important;
        //     }
        // }
    }
    &.image-right{
        @media (max-width: 768px){
            margin: 0;
            padding: 0;
            .image{
                order: -1;
                
            }
        }
        // @media (min-width: 992px) and (max-width: 1200px){
        //     .paragraph-wrapper{
        //         margin-left: 0 !important;
        //     }
        // }
    }

    .paragraph{
        z-index: 2;
    }


    .paragraph-wrapper{
        background-color: $grey;
        margin-right: -100px;
        margin-left: -100px;
        padding: 70px 45px;
        z-index: 2;
        @media (max-width: 768px){
            padding: 30px;
            width: 100%;
            margin: -60px 0 0 0;
        }
    }
    .buttons{
        float: right;
        margin-right: -30px;
        @media (max-width: 768px){
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 40px;
        }

        .btn.btn-primary{
            border: none;
            border-radius: 0;
            margin: 0;
            font-size: 1.8rem;
            font-weight: bold;
            background-color: $blue;
            margin: 0;
            padding: 15px 30px;
        }

        .btn.btn-secondary{
            border: none;
            border-radius: 0;
            margin: 0;
            font-size: 1.8rem;
            font-weight: bold;
            background-color: $orange;
            margin: 0;
            padding: 15px 30px;
        }
    }
}