$bg: #FBFAFA;
$grey: #e9e9e8;
$dark-grey: #4A4A4A;
$dark-grey-25: #4A4A4A25;
$dark-grey-50: #4A4A4A50;

$blue: #233040;
$white: #fff;
$black: #000;
$text-shadow: 2px 1px 4px rgba(0, 0, 65, 0.3);

$orange: #ff440c;
$green: #50AE1F;
$gold: #C09436;