.tour-extras-block{
    margin-top: 100px;
    margin-bottom: 100px;
    @include media-breakpoint-down(md){
        .col-md{
            margin-top: 30px;
        }
    }
    h4{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    ul{
        padding-left: 25px;
        li{
            font-weight: 400;
            line-height: 22px;
            list-style: none;
            position: relative;
            margin-bottom: 6px;
            &::before{
                background-color: transparent;
                content: '';
                position: absolute;
                height: 16px;
                width: 16px;
                top: 4px;
                left: -25px;
            }
        }
    }
    .Included{
        ul{
            li{
                &::before{
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+ODg1QTcxRkUtRTg1OC00NkY0LTkyQjEtMUJERTlCOTgxRjJGPC90aXRsZT4KICAgIDxnIGlkPSJOw710dC1PdmVyY2FzdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlNJTkdMRS1MQVJHRS1UT1VSIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjYyLjAwMDAwMCwgLTQxMzUuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjYyLjAwMDAwMCwgNDA5NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNDEuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgc3Ryb2tlPSIjNEE0QTRBIiBvcGFjaXR5PSIwLjI1IiBjeD0iOCIgY3k9IjgiIHI9IjcuNSI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTEzIiBzdHJva2U9IiNGMzZCMTYiIHN0cm9rZS13aWR0aD0iMS4yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHBvaW50cz0iNS4xNDk1ODQ2MSA4IDggMTAuODUwNDE1NCAxNC43Njc4MzY0IDEuNjg1Nzk4MjgiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
                }
            }
        }
    }
    .Optional.extras{
        ul{
            li{
                &::before{
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+NjYwRjVFMjctN0RFOS00NzZCLUJDMDgtRjkwQjJEMDk2MzREPC90aXRsZT4KICAgIDxnIGlkPSJOw710dC1PdmVyY2FzdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlNJTkdMRS1MQVJHRS1UT1VSIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTAwLjAwMDAwMCwgLTQxMzUuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjYyLjAwMDAwMCwgNDA5NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC05IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MzguMDAwMDAwLCA0MS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLUNvcHktNCIgc3Ryb2tlPSIjNEE0QTRBIiBvcGFjaXR5PSIwLjI1IiBjeD0iOCIgY3k9IjgiIHI9IjcuNSI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjAwMDAwMCwgNS4wMDAwMDApIiBzdHJva2U9IiNGMzZCMTYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjIiPgogICAgICAgICAgICAgICAgICAgICAgICA8bGluZSB4MT0iMCIgeTE9IjMiIHgyPSI2IiB5Mj0iMyIgaWQ9IlBhdGgtMTMtQ29weS0yIj48L2xpbmU+CiAgICAgICAgICAgICAgICAgICAgICAgIDxsaW5lIHgxPSIzIiB5MT0iNiIgeDI9IjMiIHkyPSIwIiBpZD0iUGF0aC0xMy1Db3B5LTMiPjwvbGluZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
                }
            }
        }
    }
    .Not.included{
        ul{
            li{
                &::before{
                    background-image: none;
                    border: 1px solid $dark-grey-25;
                    border-radius: 50%;
                }
                &::after{
                    background-color: $orange;
                    content: '';
                    height: 1px;
                    left: -20px;
                    position: absolute;
                    top: 12px;
                    width: 6px;
                }
            }
        }
    }
}