.staff-member{
    &.card{
        margin-bottom: 30px;
        border: none;
        background-color: transparent;

        .card-name{
            font-size: 2rem;
            font-weight: bold;
            color: $blue;
        }
        .card-title{
            font-size: 1.8rem;
            color: $dark-grey;
        }
    }
}