.tour-highlights-block{
    margin-bottom: 60px;
    >.row{
        overflow: scroll;
    }
    .highlight-container{
        @include media-breakpoint-down(md){
            margin-bottom: 20px;
        }
        &:hover{
            transform: scale(1.03);
            transition: transform .3s linear;
        }
        img{
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .text-container{
            bottom: 30%;
            height: 30%;
            left: 0;
            padding-top: 60px;
            position: absolute;
            width: 100%;
            .overlay{
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, transparent 100%);
                bottom: 0;
                left: 0;
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 80%;
            }
            .title{
                bottom: 81px;
                color: $white;
                font-size: 22px;
                font-weight: 700;
                position: relative;
                z-index: 10;
                bottom: 0;
            }
        }      
    }
    .scroll{
        cursor: pointer;
        height: 84px;
        right: -42px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        &.left{
            transform: translateY(-50%) rotate(0);
            right: unset;
            left: -42px;
        }
        @include media-breakpoint-down(md){
            display: none;
        }
    }
}