.tour-intro-block{
    margin-bottom: 60px;
    .pre-title{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    h1{
        color: $blue;
        font-size: 54px;
        font-weight: 700;
        line-height: 65px;
        margin-bottom: 20px;
    }
    p{
        font-size: 28px;
        font-weight: 300;
        line-height: 38px;
        @include media-breakpoint-down(md){
            font-size: 18px;
            line-height: 24px;
        }
    }
    .booking-section{
        .tour-code{
            font-size: 14px;
            font-weight: 700;
            opacity: .5;
            top: 20px;
            @include media-breakpoint-down(md){
                right: 20px;
                top: unset;
                bottom: 70px;
            }
        }
        span{
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
        }
        .price{
            font-size: 28px;
            line-height: 34px;
            color: $blue;
            margin-bottom: 17px;
            font-weight: 700;
        }
        a{
            background-color: $orange;
            border-radius: 4px;
            color: $white;
            font-weight: 700;
            height: 53px;
            padding: 12px 55px;
            
        }
    }
}