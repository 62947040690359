footer{
    .container-fluid{
        overflow-x: hidden;
        @media (max-width: 768px){
            overflow-x: unset;
            padding-right: 0;
        }
        .footer-content{
            padding-top: 40px;
            @media (max-width: 768px){
                padding: 20px 40px;
            }
            @media (max-width: 640px){
                padding: 14px;
            }
            p.mail-description{
                line-height: 22px;
                @media (max-width: 520px){
                    max-width: 70%;
                    float: right;
                    padding-right: 10px;
                }
            }
            ul{
                font-size: 1.6rem;
                line-height: 3.2rem;
                color: $dark-grey;
                list-style: none;
                margin: 0;
                padding: 0;
                font-weight: 300;
                @media (max-width: 767px){
                    line-height: 2.8rem;
                }
                @media (max-width: 640px){
                    line-height: 2.4rem;
                }
                li{
                    a, a:hover, a:visited{
                        color: $dark-grey;
                    }
                }
            }
        }
    }
    .container{
        @media (max-width: 992px){
            max-width: 840px !important;
        }
        @media (max-width: 767px){
            margin-right: 0;
            padding-right: 0;
        }
        .row:not(.header-row){
            height: 100%;
        }
        .row.header-row{
            height: 100px;
            max-height: 80px;
            @media (max-width: 767px){
                margin-left: 0;
                width: 100%;
                margin-right: 0;
            }
        }
        .info-sections{
            @media (min-width: 421px) and (max-width: 767px){
                flex: 0 0 31%;
                max-width: 31%;
                padding-top: 20px;
                padding-right: 8px;
                padding-left: 8px;
                margin-bottom: 60px;
            }
            .footer-title{
                font-size: 2.4rem;
                color: $blue;
                margin-bottom: 40px;
                font-weight: bold;
                @media (max-width: 767px){
                    font-size: 2.05rem;
                    margin-bottom: 10px;
                }
                @media (max-width: 640px){
                    font-size: 2.2rem;
                    margin-bottom: 0px;
                }
            }
            ul{
                a{
                    font-weight: 500;
                }
            }
        }
        .third-section{
            @media (max-width: 640px){
                margin-bottom: 26px;
                li{
                    display: inline;
                    padding-right: 12px;
                }
            }
        }
        .first-section, .second-section{
            @media (max-width: 640px){
                width: 50%;
            }
        }
    }
    .bg-light-gray{
        background-color: $grey;
        height: 400px;
        @media (max-width: 767px){
            padding: 0;
            height: 100%;
            .container{
                max-width: 100vw;
                .row{
                    width: 100vw;
                    justify-content: center;
                }
            }
        }
    }
    .logo-list-col{
        .logo-list{
            display: flex;
            li{
                padding: 0 20px;
                img{
                    height: 74px;
                    filter: grayscale(100%);
                    &:hover{
                        filter: none;
                        height: 74px;
                        overflow: hidden;
                        transition: transform .5s ease;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}