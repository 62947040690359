.staff-block{
    margin-top: 175px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;

    &.block-title .staff-block-title{
        height: 75px;
        font-size: 2.8rem;
        line-height: 75px;
        color: $white;
        font-weight: bold;
        padding-left: 50px;
        padding-right: 50px;
        background-color: $blue;
        position: absolute;
        top: -75px;
        right: 32%;
        &:after {
            content: '';
            position: absolute;
            left: 15%;
            bottom:-22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $blue transparent transparent transparent;
            z-index: 5;
        }
    }

    +.staff-block:not(.block-title){
        margin-top: -100px;
        padding-top: 0;
    }
}