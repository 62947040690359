.paragraph-image-block{
    margin-top: 100px;
    margin-bottom: 100px;
    .image-wrapper{
        padding-right: 20px !important;
        padding-left: 20px !important;
        position: relative;
        &:not(.slider-active){
            .prev-slide, .next-slide{
                display: none !important;
            }
        }
        img{
            @media (max-width: 992px){
                height: auto;
                object-fit: cover;
                width: 100%;
                max-height: 400px;
            }
        }
    }
    &.image-left{
        .paragraph-image-row{
            justify-content: space-between;
        }
        .paragraph-wrapper{
            order: 6;
            @media (max-width: 992px){
                margin-top: 26px;
            }
        }
        .image-wrapper{
            order: 1;

        }
        @media (max-width: 992px){
            .image-wrapper{
                img{
                    width: 100%;
                    max-height: 420px;
                    object-fit: cover;
                }
            }
        }

    }
    &.image-right{
        .image-wrapper{
            @media (max-width: 992px){
                order: 1;
            }
            img{
                @media (max-width: 992px){
                    width: 100%;
                    max-height: 420px;
                    object-fit: cover;
                }
            }
        }
        .paragraph-wrapper{
            @media (max-width: 992px){
                order: 2;
                margin-top: 26px;

            }
        }
    }
    .paragraph-wrapper{
        @include media-breakpoint-up(lg){
            padding-right: 30px !important;   
            padding-left: 30px !important;
        }
    }
    .slick-next, .slick-prev{
        background-color: transparent;
        border: none;
        height: 88%;
        position: absolute;
        top: 0;
        width: 35px;
        &:active, &:focus{
            border: none;
            box-shadow: none;
            outline: none;
        }
        &:after{
            content: url("data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDE0TC02LjEyOTcxZS0wNyAyOEwtMy42MTg0M2UtMDcgMjIuMjU0OUw2Ljc4NzIzIDE0TDMuNzQ4NTZlLTA3IDUuNDAxMTRMNi4xMDk0OGUtMDcgLTQuODA4MjVlLTA3TDExIDE0WiIgZmlsbD0iI0EyQTJBMiIvPgo8L3N2Zz4K");
            position: absolute;
            top: 50%;
            @include media-breakpoint-down(sm){
                content: none;
            }
        }
    }
    .slick-next{
        right: -15px;
        &::after{
            right: 7px;
            transform: translateY(-50%);
        }
        @include media-breakpoint-down(sm){
            right: 0;
        }
    }
    .slick-prev{
        left: -15px;
        &:after{
            left: 7px;
            transform: translateY(-50%) rotate(180deg);
        }
        @include media-breakpoint-down(sm){
            left: 0;
        }
    }
    ul.slick-dots{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding-left: 0;
        li{
            list-style: none;
            margin-right: 12px;
            &.slick-active{
                button{
                    background-color: $orange;
                }
            }
            button{
                background-color: lightgrey;
                border: none;
                border-radius: 50%;
                color: transparent;
                height: 13px;
                outline: none;
                width: 13px;
                &:active, &:focus{
                    border: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}