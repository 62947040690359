.page-blogindexpage{
    .header_img{
        height: 750px;
        background-size: cover;
        position: relative;
        .h1-container{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: $orange;
            display: inline;
            height: 100px;
            margin-bottom: 0;
            padding-left: 120px;
            padding-right: 120px;
            @include media-breakpoint-down(sm){
                align-items: center;
                display: flex;
                padding-top: 20px;
                padding-bottom: 16px;
            }
            h1{
                line-height: 100px;
                color: white;
                font-size: 4.5rem;
                font-weight: bold;
                @include media-breakpoint-down(sm){
                    white-space: normal;
                    line-height: 5rem;
                }
            }
            &:after{
                content: '';
                position: absolute;
                right: 10%;
                bottom:-22px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 22px 22px 0 22px;
                border-color: $orange transparent transparent transparent;
                z-index: 5;
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
            @include media-breakpoint-down(sm){
                max-width: 100vw;
                width: 100vw;
                text-align: center;
                padding: 20px;
            }
        }
    }
    .excerpt{
        margin: 100px auto;
        @media (max-width: 992px){
            margin: 50px auto;
        }
        .rich-text{
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: $dark-grey;
        }
    }
    .card{
        margin-bottom: 30px;
        border: none;

        .card-title{
            font-size: 2.5rem;
            font-weight: bold;
            color: $blue;
        }
        .card-subtitle{
            font-size: 2rem;
            color: $orange;
        }
    }
}
.blogs{
    margin-bottom: 30px;
}


.header_img{
    height: 750px;
    background-size: cover;
    position: relative;
    background-position: center;
    .h1-container{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $orange;
        display: inline;
        height: 100px;
        margin-bottom: 0;
        padding-left: 120px;
        padding-right: 120px;
        white-space: nowrap;
        @include media-breakpoint-down(sm){
            align-items: center;
            display: flex;
            padding-top: 20px;
            padding-bottom: 16px;
        }
        h1{
            line-height: 100px;
            color: white;
            font-size: 4.5rem;
            font-weight: bold;
            @include media-breakpoint-down(sm){
                white-space: normal;
                line-height: 5rem;
            }
        }
        &:after{
            content: '';
            position: absolute;
            right: 10%;
            bottom:-22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 22px 0 22px;
            border-color: $orange transparent transparent transparent;
            z-index: 5;
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
        @include media-breakpoint-down(sm){
            max-width: 100vw;
            width: 100vw;
            text-align: center;
            padding: 20px;
        }
    }
}