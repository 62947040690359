.trip-block{
    margin-bottom: 30px;

    &:hover{
        cursor: pointer;
    }
    .card{
        height: 100%;
        .card-tag-best{
            background-color: $gold;
            height: 28px;
            position: absolute;
            width: 120px;
            border-radius: 100px;
            top: -16px;
            display: flex;
            justify-content: center;
            left: 32%;
            .tag-icon{
                position: absolute;
                bottom: 60%;
            }
            .tag-title{
                color: #fff;
                font-weight: bold;
                margin-bottom: 0px;
                display: contents;
                font-size: 1.25rem;
            }
        }
    }

    .card-body{
        background-color: $blue;
        padding: 22px 30px 130px 30px;
        position: relative;
        .btn-wrapper{
            .btn.btn-primary{
                background-color: $orange;
            }
        }

        .card-tag-popular{
            background-color: $green;
        }
        .card-tag-special{
            background-color: $orange;
        }

        .card-tag{
            height: 28px;
            position: absolute;
            width: 120px;
            border-radius: 100px;
            top: -16px;
            display: flex;
            justify-content: center;
            left: 32%;

            .tag-icon{
                position: absolute;
                bottom: 60%;
            }

            .tag-title{
                color: $white;
                font-weight: bold;
                margin-bottom: 0px;
                display: contents;
                font-size: 1.25rem;
            }
        }
        p{
            color: $white !important;
            &:hover{
                color: $white !important;
            }
        }
    }

    .card-title{
        font-size: 2.8rem;
        color: $white;
        font-weight: bold;
    }
    .rich-text{
        color: $white;
    }
    .btn-wrapper{
        position: absolute;
        height: 60px;
        bottom: 0;
        left: 0;
        right: 0;
        .btn.btn-primary{
            width: 100%;
            border: none;
            border-radius: 0;
            margin: 0;
            height: 100%;
            font-size: 2rem;
            font-weight: bold;
            line-height: 60px;
            letter-spacing: 0.1rem;
            background-color: $orange;
        }
    }
}

@include media-breakpoint-down(sm){
    .btn-wrapper{
        text-shadow: $text-shadow;
    }

}

