.tour-overview-block{
    .inner-container{
        min-width: 30%;
        margin-bottom: 70px;
        @include media-breakpoint-down(md){
            min-width: unset;
        }
        .img-container{
            min-width: 18%;
            max-width: 18%;
            @include media-breakpoint-down(md){
                min-width: 24%;
                width: 24%;
            }
            img{
                max-width: 100%;
            }
        }
        h4{
            color: $blue;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        .subtitle{
            font-size: 14px;
            font-weight: 400;
        }
    }
}