.tour-practical-info-block{
    margin-bottom: 90px;
    .title{
        margin-bottom: 49px;
    }
    .tabs{
        margin-top: 26px;
        margin-bottom: 63px;
        width: 100%;
        button{
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: $dark-grey-50;
            font-size: 16px;
            font-weight: 600;
            border-radius: 0;
            line-height: 19px;
            text-transform: uppercase;
            padding-bottom: 12px !important;
            &:first-child{
                margin-right: 63px;
            }
            &:active, &:focus{
                border: none;
                outline: none;
            }
            &.active{
                background-color: transparent;
                color: $orange;
                border-bottom: 1px solid $orange;
            }
        }
    }
    .faq-question-container{
        &.open{
            .faq-question{
                height: 100%;
            }
            .plus-icon{
                transform: rotate(45deg);
                transition: transform .2s linear;
            }
        }
        .faq-question{
            cursor: pointer;
            height: auto;
            border-bottom: 1px solid $bg;
            padding: 20px 0 19px 48px;
            @include media-breakpoint-down(md){
                padding: 20px 20px 19px 58px;
            }
            .question{
                color: $blue;
                font-size: 22px;
                font-weight: 700;
                line-height: 25px;
            }
            .faq-answer{
                display: none;
                font-size: 18px;
                line-height: 24px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .plus-icon{
            cursor: pointer;
            position: absolute;
            top: 16px;
            left: 0;
            transform: rotate(0deg);
            transition: transform .2s linear;
            @include media-breakpoint-down(md){
                left: 8px;
            }
        }
    }
    .equipment-list{
        .paragraph-wrapper{
            @include media-breakpoint-down(md){
                &:last-child{
                    margin-top: 30px;
                }
            }
            h4{
                font-size: 22px;
                line-height: 25px;
                color: $blue;
                margin-bottom: 12px;
            }
            ul{
                padding-left: 20px;
                li{
                    list-style: none;
                    position: relative;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    &::before{
                        position: absolute;
                        content: '';
                        background-color: $orange;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        left: -15px;
                        top: 8px;
                    }
                }
            }
        }
    }
}