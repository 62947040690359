
.image-slider-container{
    width: 100vw;
    margin: 40px auto;
    .slick-list{
        .slick-track{
            display: flex;
            img{
                // min-height: 370px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 50% 50%;
                height: auto;
                object-fit: cover;
                width: 100%;
                @media (min-width: 1620px){
                    min-height: 500px;
                }
            }
        }
    }
    .slick-prev{
        position: absolute;
        top: 46%;
        left: 0%;
        width: 0;
        height: 0;
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-right: 26px solid #fff;
        background-color: transparent;
        opacity: 0.7;
        @media (max-width: 540px){
            left: -4%;
        }
    }
    .slick-next{
        position: absolute;
        top: 46%;
        right: 0%;
        width: 0;
        height: 0;
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-left: 26px solid #fff;
        background-color: transparent;
        opacity: 0.7;
        @media (max-width: 540px){
            right: -4%;
        }
    }
}
.slick-lightbox{
    z-index: 10000 !important;
}
.slick-lightbox-close{
    @media (max-width: 1440px){
        top: 8%;
    }
}

